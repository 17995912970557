import { useSubscription } from '@apollo/client';
import { USER_SUBSCRIPTION } from '../../graphql/chat-queries';

export default function useSubsUser(channelType, userId) {
    const { data } = useSubscription(USER_SUBSCRIPTION, {
        variables: {
            channelType,
            userId,
        },
    });
    return { userSubs: data?.userSubscription };
}
