import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChannels, setUnreadMessages } from '../../actions/chat.actions';
import { selectUserDetailsState } from '../../selectors/user.selector';
import { CHAT_TYPES } from '../../components/messaging/consts';
import useSubsUser from './useSubsUser';

export default function useChatMsg() {
    const dispatch = useDispatch();
    const userDetails = useSelector(selectUserDetailsState);
    const { userSubs } = useSubsUser(userDetails.hostMode ? CHAT_TYPES.FOR_HOST : CHAT_TYPES.FOR_GUEST, userDetails.id);

    useEffect(() => {
        if (!userSubs) {
            return;
        }
        const col = userSubs.channelsPagedV2.collection || [];
        dispatch(setUnreadMessages(userSubs.unreadConversationCount));
        dispatch(setChannels(col));
    }, [userSubs]);
}
