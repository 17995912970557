import { gql } from '@apollo/client';

export const MESSAGE_SUBSCRIPTION = gql`
    subscription conversationSubscription($conversationSid: ID!) {
        conversationSubscription(conversationSid: $conversationSid) {
            id
            lastMessage {
                id
                body
                createdAt
                from {
                    id
                    nameAndInitial
                }
                hasPhoto
                photoUrls
                readAt
                to {
                    id
                    nameAndInitial
                }
            }
        }
    }
`;

export const USER_SUBSCRIPTION = gql`
    subscription userSubscription($channelType: ChannelFor, $userId: ID!) {
        userSubscription(userId: $userId) {
            id
            unreadConversationCount
            channelsPagedV2(page: 1, limit: 20, channelType: $channelType) {
                collection {
                    sid
                    hasUnreadMessage
                    interlocutor {
                        id
                        firstname
                        lastInitial
                        avatarAws
                    }
                    spot {
                        id
                    }
                    lastMessage {
                        body
                        createdAt
                        hasPhoto
                        from {
                            id
                            firstname
                            lastInitial
                        }
                    }
                }
                metadata {
                    totalPages
                    totalCount
                }
            }
        }
    }
`;

export const START_TYPING_MESSAGE_SUBSCRIPTION = gql`
    subscription startTypingMessageSubscription($conversationSid: ID!, $userId: ID!) {
        conversationTypingStartSubscription(conversationSid: $conversationSid, userId: $userId) {
            conversationSid
            userId
            typing
        }
    }
`;

export const STOP_TYPING_MESSAGE_SUBSCRIPTION = gql`
    subscription stopTypingMessageSubscription($conversationSid: ID!, $userId: ID!) {
        conversationTypingEndSubscription(conversationSid: $conversationSid, userId: $userId) {
            conversationSid
            userId
            typing
        }
    }
`;

export const MESSAGE_QUERY = gql`
    query messagesPaged($conversationSid: ID!, $anchorMessageId: ID, $limit: Int!) {
        messagesPaged(conversationSid: $conversationSid, anchorMessageId: $anchorMessageId, limit: $limit) {
            collection {
                id
                body
                createdAt
                from {
                    id
                    nameAndInitial
                }
                hasPhoto
                photoUrls
                readAt
                to {
                    id
                    nameAndInitial
                }
            }
        }
    }
`;
