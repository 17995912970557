import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import loadable from '@loadable/component';

import PrivateRoute from './components/private-route';
import UserLoader from './components/user-loader';
import Navigation from './components/navigation/navigation';

import hotjar from './hotjar';
import { store } from './store';
import { apolloClient } from './services/graphql.service';
import { RouteFormatter } from './routes';
import { AWS_STATIC_ASSET_HOST, PRODUCTION, RUN_MODE } from './consts/env.consts';
import { setFirstClick } from './helpers/firstClick';
import './helpers/firebase';

import '../styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'rmc-picker/assets/index.css';

// eslint-disable-next-line no-undef
__webpack_public_path__ = `${AWS_STATIC_ASSET_HOST}/packs/`;

const DogNames = loadable(() => import('./pages/dog-names'));
const DogRescues = loadable(() => import('./pages/dog-rescues'));
const DogRescuesProfile = loadable(() => import('./pages/dog-rescues/profile'));
const DogTrainers = loadable(() => import('./pages/dog-trainers'));
const AlertBlock = loadable(() => import('./components/alert-block'));
const SniffToast = loadable(() => import('./components/sniff-toast'));
const SmartBanner = loadable(() => import('./components/smart-banner'));

// HOST
const AccountPage = loadable(() => import('./pages/account'));
const AccountEditPage = loadable(() => import('./pages/account/account.edit.page'));
const Community = loadable(() => import('./pages/community'));
const SpotsListPage = loadable(() => import('./pages/spots/spots.list'));
const SpotCompletion = loadable(() => import('./pages/spot-completion'));
const SpotCreatePage = loadable(() => import('./pages/spots/create_edit'));
const SpotEditPage = loadable(() => import('./pages/edit-listing-page'));
const SpotPostPublishPage = loadable(() => import('./pages/spots/post-publish'));
const ReservationList = loadable(() => import('./pages/reservation-list'));
const HostReservationDetail = loadable(() => import('./pages/host-reservation-detail'));
const HostMarketing = loadable(() => import('./pages/host-marketing'));
const HostMemberships = loadable(() => import('./pages/host-memberships'));
const HostReviews = loadable(() => import('./pages/host-reviews'));
const HostLanding = loadable(() => import('./pages/host-landing'));
// ---

// BLOG
const Blog = loadable(() => import('./pages/blog'));
const BlogPost = loadable(() => import('./pages/blog/post'));
const BlogAuthor = loadable(() => import('./pages/blog/author'));
const BlogCategory = loadable(() => import('./pages/blog/category'));
// ---

const About = loadable(() => import('./pages/about'));
const AppPage = loadable(() => import('./pages/app'));
const BookPage = loadable(() => import('./pages/book'));
const CalendarPage = loadable(() => import('./pages/calendar'));
const CancelReservationPage = loadable(() => import('./pages/cancel-reservation/cancel-reservation.page'));
const Conversation = loadable(() => import('./pages/conversation'));
const CustomerReviews = loadable(() => import('./pages/customer-reviews'));
const Earnings = loadable(() => import('./pages/earnings'));
const EarningsMethod = loadable(() => import('./pages/earnings/method'));
const EarningsHistory = loadable(() => import('./pages/earnings/history'));
const EmailVerified = loadable(() => import('./pages/email-verified'));
const PhoneVerified = loadable(() => import('./pages/phone-verified'));
const Extras = loadable(() => import('./pages/extras'));
const FavoritesPage = loadable(() => import('./pages/favorites-page'));
const CompleteSignup = loadable(() => import('./pages/sign-up/complete-signup'));
const FiveStarsExp = loadable(() => import('./pages/five-stars'));
const Guarantee = loadable(() => import('./pages/guarantee'));
const GuestAccount = loadable(() => import('./pages/guest-account'));
const GuestAccountMenu = loadable(() => import('./pages/account/menu'));
const HostAccountMenu = loadable(() => import('./pages/account/settings'));
const GuestMemberships = loadable(() => import('./pages/guest-memberships'));
const GuestReviewPage = loadable(() => import('./pages/guest-review-page'));
const Inbox = loadable(() => import('./pages/inbox'));
const InvitePage = loadable(() => import('./pages/invite'));
const ListingsRouter = loadable(() => import('./pages/listings-router'));
const Memberships = loadable(() => import('./pages/memberships'));
const SubscriptionsNew = loadable(() => import('./pages/subscriptions/new'));
const Sniffpass = loadable(() => import('./pages/sniffpass'));
const SniffpassTrial = loadable(() => import('./pages/sniffpass/free-trial'));
const PaymentsEditPage = loadable(() => import('./pages/payments/edit'));
const PaymentsNewPage = loadable(() => import('./pages/payments/new'));
const PaymentsPage = loadable(() => import('./pages/payments'));
const PrivacyPolicy = loadable(() => import('./pages/privacy-policy'));
const ProfilePage = loadable(() => import('./pages/profile'));
const Reservation = loadable(() => import('./pages/reservation'));
const ReservationEditPage = loadable(() => import('./pages/reservation-edit'));
const SignInPage = loadable(() => import('./pages/sign-in'));
const SignUpPage = loadable(() => import('./pages/sign-up'));
const SignUpMobile = loadable(() => import('./pages/sign-up/mobile'));
const SignUpRefPage = loadable(() => import('./pages/sign-up/referral'));
const SniffVsPublic = loadable(() => import('./pages/sniff-vs-public'));
const Terms = loadable(() => import('./pages/terms-of-service'));
const TestPages = loadable(() => import('./pages/test'));
const Trust = loadable(() => import('./pages/trust'));
const VisitList = loadable(() => import('./pages/visit-list'));
const VisitInvite = loadable(() => import('./pages/visit-invite'));
const Waiver = loadable(() => import('./pages/waiver'));
const HomePage = loadable(() => import('./pages/home'));
const NotFound = loadable(() => import('./pages/not-found'));

if (!PRODUCTION) {
    console.warn('Sniffspot develop mode!');
}

if (RUN_MODE === 'DEV') {
    console.warn('enabled DEV features');
}

const CompApp = () => {
    const location = useLocation();
    const isDogRescueProfile = location.pathname.startsWith('/dog-rescues') && /\d+$/.test(location.pathname);

    return (
        <div className="wrapper">
            <SmartBanner />
            <Navigation />
            <AlertBlock />
            <SniffToast />
            <Switch>
                <Route exact path={RouteFormatter.finishYourAccount()} component={CompleteSignup} />
                <PrivateRoute exact path={RouteFormatter.payments()} component={PaymentsPage} />
                <PrivateRoute path={RouteFormatter.paymentsNew()} component={PaymentsNewPage} />
                <PrivateRoute path={RouteFormatter.paymentsEdit()} component={PaymentsEditPage} />
                <Route exact path={RouteFormatter.home()} component={HomePage} />
                <Route exact path={RouteFormatter.hostLanding()} component={HostLanding} />
                <Route exact path={RouteFormatter.hostLandingNone()} component={HostLanding} />
                <Route exact path={RouteFormatter.hostLandingSize()} component={HostLanding} />
                <Route exact path={RouteFormatter.hostLandingShort()} component={HostLanding}>
                    <Redirect to={RouteFormatter.hostLanding()} />
                </Route>
                <Route exact path={RouteFormatter.hostLandingSizeFencing()} component={HostLanding} />
                <Route exact path={RouteFormatter.customerReviews()} component={CustomerReviews} />
                <Route path={RouteFormatter.listingsVideo()} render={(props) => <ListingsRouter video {...props} />} />
                <Route path={RouteFormatter.listings2()} component={ListingsRouter} />
                <Route path={RouteFormatter.listings()} component={ListingsRouter} />
                <Route exact path={RouteFormatter.app()} component={AppPage} />
                {/* BLOG */}
                <Route exact path={RouteFormatter.blogTrainers()}>
                    <Redirect to={RouteFormatter.blogTrainersUS()} />
                </Route>
                <Route path={RouteFormatter.blogTrainersSub()} component={DogTrainers} />
                <Route exact path={RouteFormatter.blogAuthor()} component={BlogAuthor} />
                <Route exact path={RouteFormatter.blogPost()} component={BlogPost} />
                <Route exact path={RouteFormatter.blogCategory()} component={BlogCategory} />
                <Route exact path={RouteFormatter.blog()} component={Blog} />
                {/* END BLOG */}
                <PrivateRoute exact redirectTo={RouteFormatter.signInHost()} path={RouteFormatter.community()} component={Community} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} path={RouteFormatter.cancelReservation()} component={CancelReservationPage} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} path={RouteFormatter.calendar()} component={CalendarPage} />
                <PrivateRoute path={RouteFormatter.profile()} component={ProfilePage} />
                <PrivateRoute path={RouteFormatter.review()} component={GuestReviewPage} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path={RouteFormatter.earningsMethod()} component={EarningsMethod} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path={RouteFormatter.earningsHistory()} component={EarningsHistory} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path={RouteFormatter.earnings()} component={Earnings} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path={RouteFormatter.extras()} component={Extras} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path={RouteFormatter.marketing()} component={HostMarketing} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path={RouteFormatter.hostMemberships()} component={HostMemberships} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path={RouteFormatter.hostSpots()} component={SpotsListPage} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} path={RouteFormatter.hostReviews()} component={HostReviews} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path={RouteFormatter.hostAccountEdit()} component={AccountEditPage} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path={RouteFormatter.newSpot()} component={SpotCreatePage} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path={RouteFormatter.createSpot()} component={SpotCreatePage} page={3} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path={RouteFormatter.editSpot()} component={SpotEditPage} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path="/host_account/spots/:id([0-9]+)/" component={SpotEditPage} />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} path={RouteFormatter.reservationList()} component={ReservationList} />
                <PrivateRoute
                    redirectTo={RouteFormatter.signInHost()}
                    exact
                    path={RouteFormatter.reservationDetail()}
                    component={HostReservationDetail}
                />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} exact path={RouteFormatter.spotCompletion()} component={SpotCompletion} />
                <PrivateRoute
                    redirectTo={RouteFormatter.signInHost()}
                    exact
                    path={RouteFormatter.postPublishSpot()}
                    component={SpotPostPublishPage}
                />
                <PrivateRoute redirectTo={RouteFormatter.signInHost()} path={RouteFormatter.hostAccount()} component={AccountPage} />
                <PrivateRoute
                    redirectTo={RouteFormatter.signInHost()}
                    path={RouteFormatter.hostConversation()}
                    render={(props) => <Conversation hostMode={true} {...props} />}
                />
                <PrivateRoute
                    exact
                    redirectTo={RouteFormatter.signInHost()}
                    path={RouteFormatter.hostMessages()}
                    render={(props) => <Inbox hostMode={true} {...props} />}
                />
                <PrivateRoute exact path={RouteFormatter.emailVerified()} component={EmailVerified} />
                <PrivateRoute exact path={RouteFormatter.phoneVerified()} component={PhoneVerified} />
                <PrivateRoute path={RouteFormatter.guestConversation()} component={Conversation} />
                <PrivateRoute exact path={RouteFormatter.guestMessages()} component={Inbox} />
                <PrivateRoute path={RouteFormatter.visitInvite()} component={VisitInvite} />
                <PrivateRoute exact path={RouteFormatter.visitEdit()} component={ReservationEditPage} />
                <PrivateRoute path={RouteFormatter.visitView()} component={Reservation} />
                <PrivateRoute exact path={RouteFormatter.guestAccountMenu()} component={GuestAccountMenu} />
                <PrivateRoute exact redirectTo={RouteFormatter.signInHost()} path={RouteFormatter.hostSettings()} component={HostAccountMenu} />
                <Route path={RouteFormatter.signUpMobile()} component={SignUpMobile} />
                <Route path={RouteFormatter.membershipsDetail()} component={Memberships} />
                <Route path={RouteFormatter.subscriptionsNew()} component={SubscriptionsNew} />
                <Route path={RouteFormatter.sniffpassTrial()} component={SniffpassTrial} />
                <Route path={RouteFormatter.sniffpass()} component={Sniffpass} />
                <PrivateRoute path={RouteFormatter.memberships()} redirect={RouteFormatter.subscriptions()} />
                <PrivateRoute path={RouteFormatter.subscriptions()} component={GuestMemberships} />
                <PrivateRoute path={RouteFormatter.visitList()} component={VisitList} />
                <PrivateRoute path={RouteFormatter.guestAccount()} component={GuestAccount} />
                <PrivateRoute path={RouteFormatter.invite()} component={InvitePage} />
                <PrivateRoute path={RouteFormatter.favorites()} component={FavoritesPage} guestOnly={true} />
                <Route path={RouteFormatter.signUp()} component={SignUpPage} />
                <Route path={RouteFormatter.signIn()} component={SignInPage} />
                <Route path={RouteFormatter.signUpHost()}>
                    <SignUpPage signUpHost />
                </Route>
                <Route path={RouteFormatter.signUpRef()} component={SignUpRefPage} />
                <PrivateRoute path={RouteFormatter.reserve()} component={BookPage} />
                <Route path={RouteFormatter.dogNames()} component={DogNames} />
                <Route exact path={RouteFormatter.dogRes()}>
                    <Redirect to={RouteFormatter.dogRescue()} />
                </Route>
                <Route path={RouteFormatter.dogRescueSub()}>{isDogRescueProfile ? <DogRescuesProfile /> : <DogRescues />}</Route>
                <Route path={RouteFormatter.web.about()} component={About} />
                <Route path={RouteFormatter.web.trust()} component={Trust} />
                <Route path={RouteFormatter.web.waiver()} component={Waiver} />
                <Route path={RouteFormatter.web.guarantee()} component={Guarantee} />
                <Route path={RouteFormatter.web.terms()} component={Terms} />
                <Route path={RouteFormatter.web.privacy()} component={PrivacyPolicy} />
                <Route path={RouteFormatter.web.a5star()} component={FiveStarsExp} />
                <Route path={RouteFormatter.web.vsParks()} component={SniffVsPublic} />
                <Route path={'/test'} component={TestPages} />
                <Route component={NotFound} />
            </Switch>
        </div>
    );
};

const App = () => {
    useEffect(() => {
        setFirstClick();
    }, []);

    return (
        <Provider store={store}>
            <ApolloProvider client={apolloClient}>
                <UserLoader>
                    <React.Fragment>
                        <BrowserRouter>
                            <Helmet>
                                <title>Private Dog Park Rentals - safe exercise and training for your dog | Sniffspot</title>
                            </Helmet>
                            <CompApp />
                        </BrowserRouter>
                    </React.Fragment>
                </UserLoader>
            </ApolloProvider>
        </Provider>
    );
};

export default App;

hotjar();
